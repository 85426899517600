<!--
 * @Description: Description
 * @Author: zhaoqianqian
 * @Date: 2022-06-30 16:05:06
 * @LastEditors: liyunfei12354 3090384495@qq.com
 * @LastEditTime: 2024-07-19 17:07:18
-->
<template>
    <!-- 历史记录 -->
    <div :class="['history-list',$i18n.locale]" ref="sectionBox" @touchstart="touchstart" @touchmove="touchmove" >
        <div>
            <div class="header-menu">
                <span>
                    <img src="@/assets/images/sidebar/arrow-left.png" alt class="menu-info" @click.prevent="goBack()" />
                </span>
                <div>
                    <img v-if="!status" src="@/assets/images/sidebar/edit-box-line@2x.png" alt class="menu-info"
                        @click.prevent="tipsSubmit(1)" />
                    <img v-else src="@/assets/images/report/checkbox-circle-line@2x.png" alt class="menu-info img"
                        @click.prevent="tipsSubmit(2)" />
                </div>
            </div>
            <!-- <div v-for=" (item, index) in dataArray" :key="index">
                <div class="scantime">{{item.year}}</div>
                <div class="centre-menu" v-for=" (secondItem, indef) in item.data" :key="indef" @click="goToReport(index, indef)">
                    <img src="@/assets/images/Fill 14.png" alt="" class="fill-left">
                    <div class="content">
                        <span class="time">{{secondItem.createTime | getDealWith}}</span>
                        <br/>
                        <span class="title">
                            {{secondItem.biaMeasure === 1 ? $t('report.tabPane.labelList[2]') : ''}}
                            {{secondItem.biaMeasure === 1 && secondItem.staticEval === 1 && secondItem.shoulder !== 1 ? '、' : ''}}
                            {{secondItem.biaMeasure === 1 && secondItem.shoulder === 1 ? '、' : ''}}
                            {{secondItem.staticEval === 1 ? $t('report.tabPane.labelList[3]') : ''}}
                                                                                                {{secondItem.staticEval === 1 && secondItem.shoulder === 1 ? '、' : ''}}
                                                                                                {{secondItem.shoulder === 1 ? $t('report.tabPane.labelList[4]') : ''}}
                                                                                            </span>
                                                                                        </div>
                                                                                        <img src="@/assets/images/sidebar/arrow-right.png" alt="" class="arrow-right" >
                                                                                    </div>
                                                                                </div> -->
            <van-list v-model="loading" :loading-text="$t('model.btn.loading')" :finished="finished" finished-text="" @load="onLoad" :offset=10>
                <div v-if="isRead">
                    <van-cell v-for="(item, index) in list" :key="index">
                        <div class="scantime">{{ item.year }}</div>
                        <div v-for=" (secondItem, indef) in item.data" :key="indef">
                            <div class="centre-menu" v-if="isShow(secondItem)">
                                <div @click="goToReport(index, indef)">
                                    <img src="@/assets/images/Fill 14.png" alt="" class="fill-left">
                                    <div class="content">
                                        <span class="time">{{ getDealWith(secondItem.scanTime) }}</span>
                                        <br />
                                        <span class="title">
                                            {{ showReportItem(secondItem) }}
                                        </span>
                                    </div>
                                </div>
                                <img v-if="!status" src="@/assets/images/sidebar/arrow-right.png" alt=""
                                    class="arrow-right" />
                                <img v-else @click="deleteSubmit()" src="@/assets/images/report/delete-bin-2-line@2x.png"
                                    alt="" class="arrow-right1" />
                            </div>
                        </div>
                    </van-cell>
                </div>
            </van-list>
        </div>
        <div class="spring-frame" v-if="springStatus" style="background: #000000; opacity: 0.54"></div>
        <div class="spring-frame" v-if="springStatus">
            <div class="spring-frame-box">
                <h6>{{ $t('reportTips.title') }}</h6>
                <button @click="springStatus = !springStatus">{{ $t('reportTips.btnMsg') }}</button>
            </div>
        </div>
    </div>
</template>
<script>
import { findUserMeasureHistory } from '@/assets/js/apolloGql.js'
import moment from 'moment'
export default {
    filters: {
        // getDealWith(value) {
        //     return moment(value * 1000).format("MM/DD HH:mm:ss");
        // }
    },
    data() {
        return {
            dataArray: [],
            loading: false,
            finished: false,
            list: [{ year: '', data: [] }],
            page: 2,
            status: false,
            springStatus: false,
            isRead: false,
            deviceType: JSON.parse(window.localStorage.getItem('deviceType')),
        };
    },
    computed: {

    },
    mounted() {
        this.findUserMeasureHistory()
    },
    methods: {
        getDealWith(value) {
            if(this.$i18n.locale === 'ru-RU' || this.$i18n.locale==='ar-AR') {
                return moment(value * 1000).format("DD/MM HH:mm");   
            }
            return moment(value * 1000).format("MM/DD HH:mm:ss");
        },
        tipsSubmit(type) {
            if (type === 1) {
                this.status = true
            } else {
                this.status = false
            }
        },
        deleteSubmit() {
            this.springStatus = !this.springStatus
        },
        isShow(secondItem) {
            if (this.deviceType === 3) {
                return (secondItem.biaMeasure === 1 && secondItem.biaStatus === 1) || (secondItem.staticEval === 1 && (secondItem.bdaStatus === 1 || secondItem.evalStatus === 1)) || secondItem.bsShoulderStatus == 1 || (secondItem.girthMeasure == 1 && secondItem.bdaStatus == 1)
            }
            return (secondItem.biaMeasure === 1 && secondItem.biaStatus === 1) || (secondItem.staticEval === 1 && (secondItem.bdaStatus === 1 || secondItem.evalStatus === 1)) || secondItem.bsShoulderStatus == 1
        },
        onLoad() {
            this.loading = true;
            this.finished = false;
            let setTimer = setTimeout(() => {
                let length = 0
                this.list = []
                this.isRead = false
                for (let i = 0; i < this.dataArray.length; i++) {
                    this.list.push({ year: '', data: [] })
                    try {
                        this.list[i].year = this.dataArray[i].year
                        for (let index = 0; index < this.dataArray[i].data.length; index++) {
                            if (length < 10 * this.page) {
                                ++this.page;
                                this.list[i].data.push(this.dataArray[i].data[index])
                            } else {
                                break
                            }
                        }
                        this.isRead = true
                        if (length > 9 * this.page) {
                            break
                        }
                    } catch (e) {
                        console.log(e)
                    }
                }
                this.loading = false;
                this.finished = true;
                window.clearTimeout(setTimer)
            }, 1000)
        },
        goToReport(index, indef) {
            this.$router.push({
                path: '/report',
                query: { type: 1 }
            })
            this.$store.commit("setHistoryData", this.dataArray[index].data[indef])
        },
        goBack() {
            this.$router.go(-1)
        },
        findUserMeasureHistory() {
            this.$apollo
                .query({
                    query: findUserMeasureHistory,
                    fetchPolicy: 'network-only',
                    variables: {
                        deviceType: JSON.parse(window.localStorage.getItem('deviceType'))
                    },
                })
                .then((res) => {
                    const data = res.data.findUserMeasureHistory
                    if (data) {
                        this.dataArray = data.data
                        let length = 0
                        try {
                            for (let i = 0; i < this.dataArray.length; i++) {
                                this.list[i].year = this.dataArray[i].year
                                for (let index = 0; index < this.dataArray[i].data.length; index++) {
                                    if (length < 10) {
                                        ++length;
                                        this.list[i].data.push(this.dataArray[i].data[index])
                                    } else {
                                        break
                                    }
                                }
                                this.isRead = true
                                if (length > 9) {
                                    break
                                }
                            }
                        } catch (e) {
                            console.log(e)
                        }
                    }
                })
        },
        touchstart() {
            this.lastY = event.changedTouches[0].clientY // 点击屏幕时记录最后一次Y坐标
        },
        touchmove() {
            let y = event.changedTouches[0].clientY
            let st = this.$refs.sectionBox.scrollTop // 滚动条高度
            if (y >= this.lastY && st <= 0) { //若滚动到顶部且为下拉情况
                this.lastY = y
                event.preventDefault()
            }
            try {
                let clientHeight = document.querySelector('.section').clientHeight
                let scrollHeight = document.querySelector('.section').scrollHeight
                if ((st + clientHeight === scrollHeight) && y < this.lastY) { // 若滚动到底部且为上拉情况
                    this.lastY = y
                    event.preventDefault()
                }
                this.lastY = y
            } catch (e) {
                console.log(e)
            }
        },
        showReportItem(secondItem) {
            const items = []
            if (secondItem.biaMeasure === 1 && secondItem.biaStatus === 1) {
                items.push(this.$t('report.tabPane.labelList[2]'))
            }
            if (secondItem.staticEval === 1 && (secondItem.bdaStatus === 1 || secondItem.evalStatus === 1)) {
                items.push(this.$t('report.tabPane.labelList[3]'))
            }

            if (secondItem.bdaStatus === 1 && secondItem.girthMeasure == 1 && this.deviceType === 3) {
                items.push(this.$t('girth.title'))
            }
            if (secondItem.shoulder === 1) {
                items.push(this.$t('report.tabPane.labelList[4]'))
            }

            return items.join('、')
        }
    },
}
</script>
<style lang="less" scoped>
.history-list {
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    background: url(../../assets/images/report/history_bj.png);
    background-size: 100% 100%;
    position: relative;
    top: 0;

    /deep/.van-cell {
        padding: 0 !important;
        background: none;

        &:after {
            border-bottom: none
        }
    }

    .van-cell__value {
        white-space: normal;
    }

    .header-menu {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 18px 15px;
        width: 100%;
        top: 0;
        z-index: 100;

        >span {
            img {
                display: block;
            }
        }

        .menu-info {
            width: 24px;
            height: 24px;
        }

        >div {
            img {
                position: relative;
                top: 2px;
                display: inline-block;
            }

            //     font-family: PingFangSC-Regular, PingFang SC;
            //     font-weight: 400;
            //     font-size: 25px;
            //     color: #222222;
            //     background: linear-gradient(90deg, #00E3C9 0%, #009FE8 100%);
            //     -webkit-background-clip: text;
            //     -webkit-text-fill-color: transparent;
            //     span{
            //         line-height: 25px;
            //         font-size: 15px;
            // }
        }
    }

    .scantime {
        font-size: 15px;
        color: #8AA3BE;
        text-align: left;
        margin-left: 20px;
    }
    &.tr-TR,&.de-DE,&.fr-FR{
        .centre-menu {
        position: relative;
        margin: 10px 11px;
        width: 355px;
        height: 82px;
        border-radius: 4px;
        background: #303C5C;
        .content {
            position: absolute;
            top: 16px;
            left: 64px;
            width: 250px;
            text-align: left;
            color: #8AA3BE;
        }
    }
}
    .centre-menu {
        position: relative;
        margin: 10px 11px;
        width: 355px;
        height: 76px;
        border-radius: 4px;
        background: #303C5C;

        .content {
            position: absolute;
            top: 16px;
            left: 64px;
            width: 250px;
            text-align: left;
            color: #8AA3BE;

            .time {
                font-size: 14px;
            }

            .title {
                position: absolute;
                top: 22px;
                font-size: 12px;
                line-height: 14px;
            }
        }
    }

    .fill-left {
        position: absolute;
        width: 32px;
        height: 32px;
        top: 22px;
        left: 18px;
    }

    .arrow-right {
        position: absolute;
        top: 31px;
        right: 20px;
        width: 8px;
        height: 14px;
    }

    .arrow-right1 {
        position: absolute;
        top: 20px;
        right: 12px;
        width: 24px;
        height: 24px;
    }

    .spring-frame {
        position: fixed;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        margin: auto;
        text-align: center;

        .spring-frame-box {
            width: 302px;
            // min-height: 160px;
            background: rgba(255, 255, 255, 0.9);
            border-radius: 6px;
            backdrop-filter: blur(10px);
            margin: 222px auto;
            overflow: hidden;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            h6 {
                width: 238px;
                margin: 32px;
                // height: 20px;
                font-size: 13px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #111111;
                line-height: 20px;
            }

            button {
                text-align: center;
                width: 238px;
                height: 36px;
                background: linear-gradient(90deg, #00E3C9 0%, #009FE8 100%);
                border-radius: 4px;
                margin-bottom: 40px;
            }
        }
    }
}
.ar-AR{
    .header-menu{
        direction: rtl;
        .img{
            -webkit-transform: scaleX(1);  
        }
    }
    .scantime{
        text-align: right;
        margin-right: 16px;
    }
    .arrow-right{
        left: 20px;
        -webkit-transform: scaleX(-1);
    }
    .fill-left{
        left: 0;
        margin-left: 300px;
        
    }
    .centre-menu{
        height: 85px;
        .content{
        left: 0;
        margin-left: 97px;
        width: 190px;
        text-align: right;
        .title{
            position:static;
        }
    }
    } 
     .arrow-right1 {
        right: 320px;
        top: 26px;
    }
}
</style>


